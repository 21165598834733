import React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LRestaurantContact,
  LPickupList,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'PICKUP',
            sub: 'ピックアップ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/pickup/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/pickup/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン&バー',
                path: '/restaurants/',
              },
            ],
            current: {
              label: 'ピックアップ',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect">
        <LWrap>
          <LPickupList
            classification={[
              'レストラン',
              'レストラン誕生日・記念日',
              'レストランプロポーズ・サプライズ',
              'レストラン家族のお祝い',
              '個室プラン',
            ]}
            btnlabel="メニューの詳細・ご予約"
            contact=""
            createlocation={true}
          />
        </LWrap>
      </div>
      <section className="l_sect">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
            <li>料金には消費税、サービス料が含まれます。</li>
            <li>料理の表示価格は、特別な記載がない限り1名様分の料金です。</li>
            <li>
              テイクアウト商品の料金には消費税（8％）のみ含まれます。コフレ店内をご利用の場合は、サービス料を加算、消費税は10％となります。
            </li>
            <li>入荷状況によりメニュー内容に変更がある場合がございます。</li>
            <li>
              「神奈川県公共的施設における受動喫煙防止条例」に基づきレストラン・バーを全席禁煙とさせていただいております。
            </li>
            <li>
              ご入店の際は、タンクトップ・短パン・ビーチサンダルなどはご遠慮いただいております。
            </li>
          </ul>
        </LWrap>
      </section>
      <LRestaurantContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
